import { generateGuid } from '../utils/guid.js';
import { createTransferObject, mapToLowerCase } from '../utils/mapperUtils.js';
import { getBody } from '../utils/get-body.js';
import type { Tracker, Store, Debug, Events } from '../typings/index.js';
import { TrackerClient } from '../utils/tracker-client.js';

class EventsTracker {
  constructor(
    private readonly getTrackerConfig: () => Pick<
      Tracker.TrackerConfig,
      'eventApi' | 'scriptVersion'
    >,
    private readonly store?: Store.Store,
    private readonly debug?: Debug.Debugger,
    private readonly notifySubscribers?: <T extends object>(
      eventName: string,
      data: T
    ) => void,
    private readonly getCookie?: (name: string) => string | undefined
  ) {}

  /**
   * Generates an event ID if it is not provided on the object
   * @param obj GLiTREventDTO
   * @returns GLiTREvent
   */
  normalizeEventObject(obj: Events.GLiTREventDTO): Events.GLiTREvent {
    return {
      eventId: obj.eventId ?? obj.eventid ?? generateGuid(),
      sessionId: obj.sessionId,
      viewId: obj.viewId,
      viewSequence: obj.viewSequence,
      brand: obj.brandCode,
      other: obj,
    };
  }

  constructEventPayload(
    {
      eventId,
      sessionId,
      viewId,
      brand,
      viewSequence,
      other,
    }: Events.GLiTREvent,
    date: Date = new Date()
  ): Events.GLiTREventPayload {
    const cookieId =
      this.store?.get<string>('_mhtc_cId') ??
      this.getCookie?.('_mhtc_cId') ??
      generateGuid();

    this.debug?.log(cookieId);

    const vS = parseInt(`${viewSequence}`);
    return {
      eventId,
      sessionId,
      viewId,
      brandCode: brand,
      cookieId,
      viewSequence: (isNaN(vS) ? 0 : vS) ?? 0,
      clientTimestamp: date?.getTime() ?? new Date().getTime(),
      ...(other ?? {}),
    };
  }

  getEventApiUrl(providedUrl?: string) {
    // Remove double occurrences of 'next' or 'e' in the URL
    // Remove double slashes, but the first https:// should be kept
    const hasDoubleSlashAtTheBeginning = providedUrl?.startsWith('//') ?? true;
    const url = `${providedUrl ?? `//test.mhtr.be/next`}/e`
      .replace(/\/{2,}/g, '/')
      .replace(/\/next\/next\//g, '/next/')
      .replace(/\/e\/e\//g, '/e/');
    return hasDoubleSlashAtTheBeginning ? `/${url}` : url;
  }

  sendPayload(data: Events.GLiTREventPayload) {
    const config = this.getTrackerConfig();
    const transferData = createTransferObject(mapToLowerCase(data));
    this.notifySubscribers?.('mhtracker/trackevent', data);
    const eventsTrackerClient = new TrackerClient(
      this.getEventApiUrl(config.eventApi),
      this.debug ?? console
    );
    const body = getBody(this.getTrackerConfig(), transferData);
    eventsTrackerClient
      .withContentType('application/x-www-form-urlencoded')
      .withKeepalive(false)
      .post(body);
  }
}

export { EventsTracker };

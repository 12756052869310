import { tracker } from '../initoftrackerobj.js';
import { Container, Service } from 'typedi';
import { InjectLocalStorage } from '../di/container.js';
import { Store } from '../typings/index.js';

type Storage = typeof window.localStorage;

@Service()
class TrackerStorage implements Store.Store {
  constructor(@InjectLocalStorage() private readonly storage: Storage) {}

  /**
   * Checks if the storage engine is defined in this class
   * @return boolean True if the storage engine is defined, false otherwise
   */
  isLocalStorageNameSupported(): boolean {
    try {
      return !!this.storage;
    } catch (err) {
      return false;
    }
  }

  /**
   * Checks if the storage engine is running
   * This method will attempt to set a test key to
   * check whether functionality is as expected
   * @return True if the engine functions as expected, false otherwise
   */
  isLocalStorageSupported(): boolean {
    const testKey = 'test';
    try {
      this.storage.setItem(testKey, 'testValue');
      const item = this.storage.getItem(testKey);
      this.storage.removeItem(testKey);
      return item === 'testValue';
    } catch (error) {
      return false;
    }
  }

  /**
   * Returns the amount of key/value pairs currently stored in this store
   * @return number Amount of key/value pairs
   */
  get length() {
    return this.storage.length;
  }

  transact<
    T = object | string | number | null,
    K = object | string | number | null
  >(
    key: string,
    defaultVal?: T,
    transactionFn?: (value?: T | string | number | null) => K
  ) {
    const val = this.get<T>(key);
    this.set(key, transactionFn?.(val ?? defaultVal) ?? defaultVal);
  }

  /**
   * Helper method to serialize an object or primitive object
   * before storing it in the storage engine
   * @param value Object or primitive to serialize
   * @return string Serialized object
   */
  serialize(value: object | number | string | boolean) {
    return JSON.stringify(value);
  }

  /**
   * Helper method to deserialize a string back to an object
   * This method is used when retrieving items from storage
   * @param value The string to deserialize
   * @return object The object parsed from the serialized string
   */
  deserialize<T = object>(value: string): T | undefined {
    if (!value || !value.toString()) {
      return undefined;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      return undefined;
    }
  }

  /**
   * Store an item in storage
   * @param key Key by which the item can be retrieved
   * @param value Item that should be stored in storage
   */
  set<T = object>(key: string, value?: T): T | undefined {
    if (!this.isLocalStorageNameSupported()) return undefined;
    if (!value) {
      this.remove(key);
      return undefined;
    }
    this.storage.setItem(key, this.serialize(value as object));
    return value;
  }

  /**
   * Retrieves an item stored in the storage engine by key
   * @param key The key by which the item was originally stored
   */
  get<T = object>(key: string): T | undefined {
    if (!this.isLocalStorageNameSupported()) return undefined;
    return this.deserialize<T>(this.storage.getItem(key) as string);
  }

  /**
   * Remove an item from storage by key
   * @param key The key with which the item was previously stored
   */
  remove(key: string) {
    if (!this.isLocalStorageNameSupported()) return;
    this.storage.removeItem(key);
  }

  /**
   * Remove all items from the store
   */
  clear() {
    if (!this.isLocalStorageNameSupported()) return;
    this.storage.clear();
  }

  /**
   * Fetches all stored key/value pairs and returns then as object literal
   * @return object An object literal with al the key value pairs mapped on the object.
   * @return undefined If the storage engine is not functional
   */
  getAll<T = object>(): T | undefined {
    if (!this.isLocalStorageNameSupported()) return undefined;
    const ret = {};
    this.forEach(function (key, val) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ret[key] = val;
    });
    return ret as T;
  }

  /**
   * Registers and executes a callback for each key/value pair in the storage
   * @param callback Callback which accepts two parameters: the key and the value of the object
   */
  forEach(callback: (key: string, value: object | undefined | string) => void) {
    if (!this.isLocalStorageNameSupported()) return;
    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i);
      callback(key as string, this.get(key as string));
    }
  }
}

// Inject the store on the main container
tracker.store = Container.get(TrackerStorage);

// Functions to encapsulate questionable FireFox 3.6.13 behavior
// when about.config::dom.storage.enabled === false
// See https://github.com/marcuswestin/store.js/issues#issue/13
/**
 * @deprecated
 */
const isLocalStorageSupported = tracker.store.isLocalStorageSupported;
/**
 * @deprecated
 */
const isLocalStorageNameSupported = tracker.store.isLocalStorageSupported;

export { isLocalStorageSupported, isLocalStorageNameSupported, TrackerStorage };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { TrackerStorage } from '../utils/localstorage.js';
import type { Tracker, Views } from '../typings/index.js';
import { createTransferObject, mapToLowerCase } from '../utils/mapperUtils.js';
import { Service } from 'typedi';
import { generateGuid } from '../utils/guid.js';
import {
  InjectConfig,
  InjectDocument,
  InjectTracker,
  InjectWindow,
} from '../di/container.js';
import { TrackerDebugger } from '../utils/debug.js';
import { CookiesService } from '../utils/cookies.js';
import {
  getWildcardDomain,
  isSameHostname,
  normalizeUrlPart,
  parseUrl,
} from '../utils/stringUtils.js';
import { PubSubService } from '../pubsub/pubsub.service.js';
import { TrackerClient } from '../utils/tracker-client.js';
import { getBody } from '../utils/formUtils.js';

@Service()
export class ViewTracker {
  constructor(
    private readonly store: TrackerStorage,
    @InjectTracker() private readonly tracker: Tracker.Tracker,
    @InjectConfig() private readonly config: Tracker.TrackerConfig,
    private readonly debug: TrackerDebugger,
    private readonly cookies: CookiesService,
    @InjectWindow() private readonly window: Window,
    @InjectDocument() protected readonly document: Document,
    private readonly pubsub: PubSubService
  ) {}

  getCookieId(defaultCookieId?: string): string {
    if (defaultCookieId && defaultCookieId.length) return defaultCookieId;

    return (
      this.store.get('_mhtc_cId') ??
      this.cookies.getCookie('_mhtc_cId') ??
      generateGuid()
    );
  }

  setCookieId(value: string) {
    this.cookies.setCookie('_mhtc_cId', value, 31, this.getDomainName()); // 31 days
    this.store.set('_mhtc_cId', value);
    this.tracker.info.cookieId = value;
  }

  setSessionId(value: string) {
    this.cookies.setCookie(
      '_mhtc_sId',
      value,
      Math.round((1 / 24 / 2) * 1000) / 1000, // 30 min
      this.getDomainName()
    ); // 30 min
    this.store.set('_mhtc_sId', value);
    this.tracker.info.sessionId = value;
  }

  getDomainName(): string | undefined {
    const parts = this.window.location.hostname?.split('.');
    if (!parts) return undefined;
    if (parts.length > 2) return parts.slice(1).join('.');
    else return parts.join('.');
  }

  getDocumentTitle(): string {
    return (
      (this.window.utag_data?.article_title ?? this.document?.title)
        ?.replace(/(\r\n|\n|\r)/gm, '')
        ?.replace(/["'´`]+/g, '') ?? ''
    );
  }

  // this method calls the track view in another view
  logView(obj: Views.ViewObject) {
    const data = {
      sessionId: obj.sessionId,
      viewId: obj.viewid ?? obj.viewId ?? generateGuid(),
      viewSequence: obj.viewSequence,
      brandCode: obj.brandCode,
      cookieId: obj.cookieId ?? undefined,
      other: obj,
    };

    this.tracker.sharedState = this.tracker.sharedState || ({} as any);
    this.tracker.sharedState = data;

    // if there is a viewid share it
    if (data.viewId) {
      this.tracker.sharedState.viewId = data.viewId;
    }
    if (data.sessionId) {
      this.tracker.sharedState.sessionId = data.sessionId;
    }
    if (data.viewSequence) {
      this.tracker.sharedState.viewSequence = data.viewSequence;
    }
    if (data.brandCode) {
      this.tracker.sharedState.brandCode = data.brandCode;
    }
    if (obj.isnewdataformat === true) {
      this.tracker.sharedState.isnewdataformat = obj.isnewdataformat;
      this.tracker.sharedState.sessionId = obj.sessionid;
      this.tracker.sharedState.viewSequence = obj.viewsequence;
      this.tracker.sharedState.brandCode = obj.brandcode;
    }

    if (!data.sessionId) {
      data.sessionId = this.tracker.sharedState.sessionId;
    }
    if (!data.brandCode) {
      data.brandCode = this.tracker.sharedState.brandCode;
    }

    // there are two ways to set the viewid with the data.viewid
    // or via the sharedstate object. Tmg uses the sharedstate approach
    if (!obj.viewId && this.tracker.sharedState.viewId) {
      data.viewId = this.tracker.sharedState.viewId;
    }

    //each time a log is fired
    this.trackView(data as unknown as Views.ViewObject);
  }

  getUrl(data?: Views.ViewObject['other']): string {
    if (this.config.spa) {
      const loc = this.window.location;
      const path = data?.pathName || loc.pathname;
      const result =
        loc.protocol +
        '//' +
        loc.hostname +
        '/' +
        normalizeUrlPart(path) +
        location.search;
      return normalizeUrlPart(result) as string;
    }

    return this.window.location.href;
  }

  getReferrer(data?: { referrer?: string; brandcode?: string }): string | null {
    const toNullishValue = (value?: string) => (!value ? null : value);
    if (
      this.config?.spa &&
      (data?.brandcode === 'telegraaf' || data?.brandcode === 'telegraaf2') &&
      data?.referrer
    ) {
      return toNullishValue(data.referrer);
    }
    return toNullishValue(this.document.referrer);
  }

  trackView({
    sessionId,
    cookieId: cookieIdFromObj,
    viewId,
    viewSequence,
    brandCode,
    other,
  }: Views.ViewObject): void {
    const cookieId = this.getCookieId(cookieIdFromObj);
    this.debug.log(cookieId);
    this.setCookieId(cookieId);
    this.setSessionId(sessionId);
    const pageUrl = this.getUrl(other);
    const pageReferrer = this.getReferrer(other);
    const previous = this.getPrevious(pageReferrer, pageUrl);
    const data: Views.ViewPayload = {
      cookieId: cookieId,
      sessionId: sessionId,
      viewId: viewId as string,
      viewSequence: parseInt(viewSequence) || 0,
      brandCode: brandCode,
      clientTimestamp: new Date().getTime(),
      pageUrl: pageUrl,
      pageReferrer: pageReferrer,
      previousUrl: previous ? previous.url : null,
      previousPageTypes: previous ? previous.pageTypes : [],
      previousViewId: previous ? previous.viewId : null,
      previousTitle: previous ? previous.title : null,
      deviceResolution: `${this.window.screen.width}x${this.window.screen.height}`,
      deviceOrientation:
        this.window.screen.width > this.window.screen.height
          ? 'Landscape'
          : 'Portrait',
      // INM specific
      // - will fix the missing previous pageTypes being added to localstorage/cookie
      pageTypes: [other?.pagetype ?? 'unknown'],
      ...(other ?? {}),
      other: undefined,
    };

    this.setPreviousInLocalStorageOrCookie(data);

    this.tracker.info = data;
    this.debug.log('The info object is', data);
    this.debug.log('The lowercased info object is', mapToLowerCase(data));

    this.pubsub.notifySubscribers('mhtracker/trackview', data);

    const transferData = createTransferObject(mapToLowerCase(data));
    const viewsTrackerClient = new TrackerClient(
      `${this.config.viewApi}/v`,
      this.debug
    );
    const body = getBody(transferData);
    viewsTrackerClient
      .withContentType('application/x-www-form-urlencoded')
      .withKeepalive(false)
      .post(body);
  }

  getPrevious(
    pageReferrer: string | null,
    currentUrlP: string
  ): Views.ViewPath | void | null {
    const referrer = getWildcardDomain(pageReferrer);
    const currentUrl = getWildcardDomain(currentUrlP);

    const url =
      this.store.get<Views.ViewPath>('_previous')?.url ??
      this.cookies.getCookie('_previous_url');

    const previousPath = url ? this.getPath(url) : undefined;

    // telegraaf.nl is SPA, and document.referrer is correctly set, so we try to use  get previous
    if (this.config?.spa) {
      const previousPathFromDatalayerPath = this.getPreviousPathFromDataLayer();
      if (
        typeof previousPathFromDatalayerPath === 'string' &&
        previousPathFromDatalayerPath == previousPath
      ) {
        return this.getPreviousFromLocalStorageOrCookie();
      } else {
        this.removePreviousPage();
      }
    }
    // Use document.referrer to determine if this is from internal or external site
    else {
      if (isSameHostname(referrer as string, currentUrl as string)) {
        return this.getPreviousFromLocalStorageOrCookie();
      } else {
        this.removePreviousPage();
      }
      return null;
    }
  }

  getPath(url: string): string | undefined {
    return normalizeUrlPart(parseUrl(url)?.pathname) ?? undefined;
  }

  getPreviousPathFromDataLayer(): string | null | undefined {
    // Because in a SPA we're unable to use document.referrer > use datalayer to retrieve previous path
    return normalizeUrlPart(
      this.window.dataLayer
        ?.filter((dl) => dl.event === 'page_view')
        .slice(-2, -1)?.[0]
        ?.path?.split('?')[0]
    );
  }

  removePreviousPage() {
    if (this.store && this.store.isLocalStorageSupported()) {
      this.store.remove('_previous');
    } else {
      this.cookies.removeCookie('_previous_url');
      this.cookies.removeCookie('_previous_viewId');
      this.cookies.removeCookie('_previous_pageTypes');
      this.cookies.removeCookie('_previous_title');
    }
  }

  setPreviousInLocalStorageOrCookie(
    data: Partial<{ pageUrl: string; viewId: string; pageTypes: string[] }>
  ) {
    if (this.store.isLocalStorageSupported()) {
      this.store.set('_previous', {
        url: data.pageUrl,
        viewId: data.viewId,
        pageTypes: data.pageTypes,
        title: this.getDocumentTitle(),
      });
    } else {
      this.cookies.setCookie('_previous_url', data.pageUrl);
      this.cookies.setCookie('_previous_viewId', data.viewId);
      this.cookies.setCookie('_previous_pageTypes', data.pageTypes);
      this.cookies.setCookie('_previous_title', this.getDocumentTitle());
    }
  }

  getPreviousFromLocalStorageOrCookie(): Views.ViewPath | undefined {
    if (this.store.isLocalStorageSupported()) {
      return this.store.get('_previous');
    } else {
      const url = this.cookies.getCookie('_previous_url');
      const viewId = this.cookies.getCookie('_previous_viewId');
      const pageTypes = this.cookies.getCookie('_previous_pageTypes');
      const title = this.cookies.getCookie('_previous_title');
      if (url && viewId && pageTypes && title)
        return {
          url,
          viewId,
          pageTypes: pageTypes.split(','),
          title: title.split(','),
        };
    }
    return undefined;
  }
}

import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { GTMActions, ReduxStore } from '../redux/index.js';
import { Inject, Service } from 'typedi';
import { InjectionTokens } from '../di/container.js';
import type { GTM } from '../typings/index.js';

@Service()
export class GTMDayaLayerService {
  private destroy$: Subject<void> = new Subject();
  private _dataLayer?: GTM.DataLayer;

  constructor(
    private readonly reduxStore: ReduxStore,
    @Inject(InjectionTokens.window) private readonly window: Window
  ) {}

  observeDataLayer(): Observable<GTM.DataLayer> {
    // Observe the window.dataLayer variable
    // and update the redux store with the new value
    // with a proxy
    return new Observable<GTM.DataLayer>((observer) => {
      const originalDataLayer = this.window.dataLayer ?? [];
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self: GTMDayaLayerService = this;

      Object.defineProperty(this.window, 'dataLayer', {
        get: () => {
          if (!self._dataLayer) {
            return;
          }
          return new Proxy(self._dataLayer, {
            set(target, property, value) {
              // Make the target extensible if it is not
              if (!Object.isExtensible(target)) {
                target = Array.from(target); // Create a new, extensible array from the existing one
                self._dataLayer = target; // Update _dataLayer reference
              }
              if (property === 'length') {
                observer.next(target);
              } else {
                // Perform the original operation for other properties
                target[property as keyof typeof property] = value;
              }

              // Update self._dataLayer and notify the observer
              self._dataLayer = [...target];

              // Indicate that the operation was successful
              return true;
            },
          });
        },
        set: (value) => {
          // Update self._dataLayer directly
          self._dataLayer = value;
          observer.next(value);
        },
        configurable: true,
      });
      // Initialize _dataLayer
      this._dataLayer = [...originalDataLayer];
      observer.next(originalDataLayer);
    });
  }

  startObservingDataLayer() {
    this.stopObservingDataLayer();
    this.observeDataLayer()
      .pipe(
        takeUntil(this.destroy$),
        tap((dataLayer) =>
          this.reduxStore
            .getStore()
            .dispatch(GTMActions.pushFromDataLayer({ dataLayer }))
        )
      )
      .subscribe();
  }

  stopObservingDataLayer() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject();
  }
}

import { tracker } from './initoftrackerobj.js';
import type { Heartbeats } from './typings/index.js';
import { getBody } from './utils/formUtils.js';
import { createTransferObject, mapToLowerCase } from './utils/mapperUtils.js';
import { TrackerClient } from './utils/tracker-client.js';

tracker.trackHeartbeat = trackHeartbeat;

function trackHeartbeat(attentionSpan: number, totalPageAttentionSpan: number) {
  //tracker.log('trackHeartbeat - tracker.video = ' + tracker.video);

  // Start video tracking
  if (!tracker.video) {
    //tracker.log('trackHeartbeat - start video detection');
    tracker.detectTheoPlayer();
    tracker.detectYoutube();
  }

  tracker.config.trackHeartbeat = {
    latest: new Date().getTime(),
  };

  const sharedState = tracker.sharedState || ({} as any);
  const data: Heartbeats.GLiTRHeartbeat = {
    viewId: sharedState.viewId,
    viewSequence: parseInt(sharedState.viewSequence) ?? 0,
    clientTimestamp: tracker.config.trackHeartbeat.latest ?? 0,
    pageAttentionSpan: attentionSpan,
    totalPageAttentionSpan: totalPageAttentionSpan,
    brandCode: sharedState.brandCode,
    isnewdataformat: sharedState.isnewdataformat || false,
  };

  if (tracker.video) {
    data.videoPaused = tracker.video.paused ?? false;
    data.videoPlayed = tracker.video.played ?? false;
    data.videoEnded = tracker.video.ended ?? false;
    data.videoFurthestPlay = tracker.video.furthestPlay;
    data.hasVideo = tracker.video.hasVideo;
    //tracker.log("trackHeartbeat - tracker.video.hasVideo = " + data.hasVideo);
  }
  if (tracker.adBlockers) {
    data.adblock = tracker.adBlockers.adblock;
    data.adblockSP = tracker.adBlockers.adblockSP;
    data.adblockWebcms = tracker.adBlockers.adblockWebcms;
  }

  const transferData = createTransferObject(mapToLowerCase(data));
  //retryCount = 1 for heartbeatbeacuse every 5 seconds a heartbeat is send
  const heartbeatsTrackerClient = new TrackerClient(
    `${tracker.config.heartbeatApi}/h`,
    tracker.debugger
  );

  const body = getBody(transferData);
  heartbeatsTrackerClient
    .withContentType('application/x-www-form-urlencoded')
    .post(body);
}

//https://support.theoplayer.com/hc/en-us/articles/214343065-Player-API
/* eslint-disable @typescript-eslint/no-explicit-any */
import { tracker } from './initoftrackerobj.js';

function receiveIVVIMessage(event: { data: any }) {
  const data = event.data;

  if (
    Object.prototype.hasOwnProperty.call(data, 'videoID') ||
    Object.prototype.hasOwnProperty.call(data, 'video_id')
  ) {
    tracker.video = tracker.video || {};
    tracker.video.hasVideo = true;

    const eventType = (data.data && data.data.type) || data.video_event_type;
    if (eventType) {
      if (eventType == 'play') {
        tracker.video.played = true;
        tracker.isPlayingVideo = true;
        tracker.startedPlayingVideoOn = new Date();
      } else if (eventType == 'pause') {
        tracker.video.paused = true;
        tracker.isPlayingVideo = false;
      } else if (eventType == 'ended') {
        tracker.video.ended = true;
        tracker.isPlayingVideo = false;
      }
    }
  }
}

window.addEventListener('message', receiveIVVIMessage, false);

function detectTheoPlayer() {
  const hasVideo = document.querySelector('.video-js') !== null;
  //tracker.log("detectTheoPlayer - check .video-js = " + hasVideo);
  if (hasVideo) {
    tracker.video = tracker.video || {};
    tracker.video.hasVideo = true;
    //tracker.log("detectTheoPlayer - tracker.video.hasVideo = " + tracker.video.hasVideo);
    return true;
  }
  return false;
}

tracker.detectTheoPlayer = detectTheoPlayer;

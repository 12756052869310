import { tracker } from './initoftrackerobj.js';
import { Container } from 'typedi';
import { PubSubService } from './pubsub/pubsub.service.js';

if (tracker && tracker.kill) {
  tracker.kill();
}

tracker.info = {};

tracker.isAlive = true;
tracker.isPlayingVideo = false;
tracker.startedPlayingVideoOn = new Date();
tracker.totalPageAttention = 0;
tracker.lastTotalPageAttention = 0;
tracker.wasActiveOn = new Date();
tracker.kill = kill;
tracker.handleHeartbeat = handleHeartbeat;
tracker.eventsHandled = 0;

const heartbeatSpeeds = [5, 10];
const MS_IN_SECONDS = 1000;

function getNextTimeoutForHeartbeat() {
  let timeout = 0;
  if (heartbeatSpeeds.length > 1) {
    timeout = heartbeatSpeeds.shift() ?? 0;
  } else {
    timeout = heartbeatSpeeds[0];
  }
  return (timeout ?? 0) * MS_IN_SECONDS;
}

function isAlive() {
  const wasActive = (tracker.wasActiveOn ?? 0) > addMinutes(new Date(), -1 / 6);
  const isPlayingVideo =
    tracker.isPlayingVideo &&
    (tracker.startedPlayingVideoOn ?? 0) > addMinutes(new Date(), -15);
  const alive = tracker.isAlive && (wasActive || isPlayingVideo);
  //tracker.log('checking if page is alive', alive);
  return alive;
}

function breath() {
  const alive = isAlive();

  tracker.log(
    (alive ? 'Attention' : 'No attention') +
      ' - Total page attention: ' +
      tracker.totalPageAttention +
      ' ms + (' +
      tracker.totalPageAttention / 1000 +
      's)' +
      ' - No event activity since ' +
      (new Date().getTime() - (tracker.wasActiveOn?.getTime() ?? 0)) / 1000 +
      's' +
      ' - Video playing ' +
      tracker.isPlayingVideo
  );

  handleHeartbeat();
  if (tracker.config.heartbeatEnabled) {
    setNextheartbeat();
  }
}

tracker.notifySubscribers = (eventName: string, obj: object) =>
  Container.get(PubSubService).notifySubscribers(eventName, obj);

function lookAround() {
  const alive = isAlive();
  tracker.totalPageAttention += alive ? tracker.config?.watchSpeed ?? 0 : 0;
  tracker.lookArroundTimeoutId = setTimeout(
    lookAround,
    tracker.config.watchSpeed
  );
}

// TMG function is called from the haylayer
tracker.resetHeartbeat = function () {
  if (
    tracker.initialized?.trackView &&
    tracker.config.heartbeatEnabled &&
    typeof tracker.trackHeartbeat === 'function'
  ) {
    tracker.totalPageAttention = 0;
    tracker.lastTotalPageAttention = 0;
    tracker.video = null;
    tracker.log('tracker.resetHeartbeat - tracker.video = ' + tracker.video);
  }
};

function handleHeartbeat() {
  if (
    tracker.initialized?.trackView &&
    tracker.config.heartbeatEnabled &&
    tracker.trackHeartbeat
  ) {
    const attention =
      (tracker.totalPageAttention - tracker.lastTotalPageAttention) / 1000;
    if (attention > 0) {
      tracker.trackHeartbeat(attention, tracker.totalPageAttention / 1000);
    }
    tracker.lastTotalPageAttention = tracker.totalPageAttention;
    tracker.eventsHandled++;
  }
}

function setNextheartbeat() {
  tracker.heartbeatTimeoutId = setTimeout(breath, getNextTimeoutForHeartbeat());
}

function startLooking() {
  tracker.lookArroundTimeoutId = setTimeout(
    lookAround,
    tracker.config.watchSpeed
  );
}

function kill() {
  clearTimeout(tracker.lookArroundTimeoutId);
  clearTimeout(tracker.heartbeatTimeoutId);
}

tracker.startHeartbeat = function () {
  if (tracker.config.heartbeatEnabled && !tracker.heartbeatTimeoutId) {
    setNextheartbeat();
    startLooking();
  }
};

function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

// function getUrlParams() {
//   const params: { [key: string]: string } = {};
//   decodeURIComponent(window.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str: string, key, value): string {
//     const v = encodeURIComponent(value.replace(/["'´`]+/g, ''));
//
//     params[key.toLowerCase()] = v;
//     return v;
//   });
//
//   return params;
// }

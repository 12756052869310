/* eslint-disable @typescript-eslint/no-explicit-any */
/*
IMPORTANT: this code is shared between the tracker clientside and server side when doing modification please add it here and
update the server side tracker too.
*/
const longNamesToShortNamesMap: { [key: string]: string } = {
  application: 'a',
  appLoadedByMobileSdk: 'apploadmsdk',
  appName: 'appn',
  appVersion: 'appv',
  articleAccessType: 'aAT',
  articleAuthor: 'aA',
  articleBodyCharCount: 'aBCC',
  articlecategorylist: 'articlecategorylist',
  articleContentType: 'aCT',
  articleexternalid: 'articleexternalid',
  articleId: 'aID',
  articleIsPlusLayout: 'aIPL',
  articlePublicationDate: 'aPD',
  articlePublicationTime: 'aPT',
  articleSource: 'aS',
  articleTitle: 'aTi',
  articleType: 'aT',
  brandCode: 'b',
  canonicalurl: 'canonicalurl',
  campaignId: 'caId',
  carrierMCC: 'mcc',
  carrierMNC: 'mnc',
  cityName: 'c',
  clientTimestamp: 'cT',
  cookieId: 'cId',
  device: 'dev',
  deviceArchitecture: 'deva',
  deviceBrowserFamily: 'debf',
  deviceCarrier: 'dvc',
  deviceConnectionType: 'devct',
  deviceCpuType: 'devcput',
  deviceId: 'devi',
  deviceLanguage: 'dvl',
  deviceOrientation: 'devo',
  deviceOSVersion: 'devos',
  devicePlatform: 'dvp',
  deviceResolution: 'dvr',
  deviceType: 'devt',
  deviceUUID: 'dvu',
  dnagenre: 'dnagenre',
  dnamedium: 'dnamedium',
  dnaorientation: 'dnaorientation',
  dnarationality: 'dnarationality',
  dnareadingtime: 'dnareadingtime',
  dnareason: 'dnareason',
  dnauserneed: 'dnauserneed',
  environment: 'e',
  fulllength: 'fulllength',
  glitrapiversion: 'glitrapiversion',
  glitrfrontendversion: 'glitrfrontendversion',
  identityAccountId: 'iAI',
  identityAccountType: 'iAcT',
  identityAuthenticationLevel: 'iAL',
  identityAuthenticationType: 'iAT',
  identityMessagentAccountId: 'iMAI',
  identityPrivacyAcceptCookies: 'iPAC',
  identityPrivacyCookieBannerShown: 'iPCBS',
  itemdescription: 'itemdescription',
  itemoptions: 'itemoptions',
  key: 'k',
  loginstatus: 'loginstatus',
  mediaaccount: 'mediaaccount',
  mediabrand: 'mediabrand',
  mediaid: 'mediaid',
  mediapublicationdateinutc: 'mediapublicationdateinutc',
  mediatitle: 'mediatitle',
  outputid: 'outputid',
  pageAttentionSpan: 'pAS',
  pageChannel: 'pgc',
  pageExtraFields: 'pEF',
  pageLocations: 'pL',
  pageMainTag: 'pMT',
  pagePaywallShown: 'pPS',
  pageReferrer: 'pR',
  pageSearchTerm: 'pSeT',
  pageSectionTrees: 'pSTS',
  pageSecure: 'pSe',
  pagesecuretype: 'pagesecuretype',
  pageTypes: 'pT',
  pageUrl: 'pU',
  pageutmcampaign: 'pageutmcampaign',
  pageutmcontent: 'pageutmcontent',
  pageutmmedium: 'pageutmmedium',
  pageutmsource: 'pageutmsource',
  pageutmterm: 'pageutmterm',
  playerid: 'playerid',
  previous: 'prev',
  previousPageTypes: 'prPT',
  previousTitle: 'prt',
  previousUrl: 'prU',
  previousViewId: 'prVId',
  province: 'pr',
  publicationApp: 'puA',
  publicationDate: 'puD',
  publicationEdition: 'pEd',
  publicationName: 'puN',
  publicationPage: 'puP',
  sessionId: 'sId',
  subscriptionid: 'subscriptionid',
  subscriptionStatus: 'subscriptionStatus',
  subscriptionType: 'sT',
  totalPageAttentionSpan: 'tpAS',
  utmartid: 'utmartid',
  utmCampaign: 'uCa',
  utmcid: 'utmcid',
  utmContent: 'uCo',
  utminternal: 'utminternal',
  utmMedium: 'uM',
  utmprsid: 'utmprsid',
  utmSource: 'uS',
  utmTerm: 'uT',
  value: 'v',
  viewId: 'vId',
  viewSequence: 'vS',
  zipCode: 'lP',
};

let disableShortnames = false;
const shortnamesToLongnamesMap: { [key: string]: string } = {};

function getShortname(longName: string): string {
  return (
    longNamesToShortNamesMap[
      longName as keyof typeof longNamesToShortNamesMap
    ] ?? longName
  );
}

function getLongname(shortname: keyof typeof shortnamesToLongnamesMap): string {
  return shortnamesToLongnamesMap[shortname] ?? (shortname as string);
}

function mapToLowerCase<
  T extends object & { isnewdataformat?: boolean },
  K extends object
>(src: T): K | T {
  return src.isnewdataformat
    ? (Object.keys(src).reduce((acc, key) => {
        return {
          ...acc,
          [key.toLowerCase()]: src[key as keyof typeof src],
        };
      }, {}) as K)
    : src;
}

function createTransferObject<
  T extends object & { disableShortnames?: boolean }
>(src: T): T {
  return disableShortnames || src.disableShortnames
    ? {
        ...src,
        disableShortNames: true,
      }
    : clone(src, getShortname);
}

function createFromTransferObject<
  T extends object & { disableShortnames?: boolean }
>(src: T): T {
  return disableShortnames || src.disableShortnames
    ? src
    : clone(src, getLongname);
}

function initShortnamesToLongamesMap() {
  for (const name in longNamesToShortNamesMap) {
    const longName = name;
    const shortname =
      longNamesToShortNamesMap[
        longName as keyof typeof longNamesToShortNamesMap
      ];

    if (
      !Object.prototype.hasOwnProperty.call(shortnamesToLongnamesMap, shortname)
    ) {
      shortnamesToLongnamesMap[
        shortname as keyof typeof shortnamesToLongnamesMap
      ] = longName;
    } else {
      throw new Error('duplicate name in shortnameobject " ' + shortname + '"');
    }
  }
}

function init() {
  initShortnamesToLongamesMap();
  const exp = {
    createFromTransferObject: createFromTransferObject,
    createTransferObject: createTransferObject,
    clone: clone,
    mappedNames: longNamesToShortNamesMap,
    mapToLowerCase: mapToLowerCase,
  };

  if (typeof process !== 'undefined' && !(process as any).browser) {
    module.exports = exp;
  } else {
    window._mhtracker.mapperUtils = exp;
    disableShortnames = !!window._mhtracker.config.disableShortnames;
  }
}

function clone(item: any, translateCallback: (v: string) => string) {
  if (!translateCallback) {
    translateCallback = function (name) {
      return name;
    };
  }

  if (!item) {
    return item;
  } // null, undefined values check

  const types = [Number, String, Boolean];

  let result;
  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item);
    }
  });

  if (typeof result == 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = [];
      item.forEach(function (child: any, index: number) {
        result[index] = child;
      });
    } else if (typeof item == 'object') {
      // testing that this is DOM
      if (item.nodeType && typeof item.cloneNode == 'function') {
        result = item;
      } else if (!item.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item);
        } else {
          // it is an object literal
          result = {};
          for (const i in item) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result[translateCallback(i)] = item[i];
          }
        }
      } else {
        // depending what you would like here,
        // just keep the reference, or create new object
        if (item.constructor) {
          // would not advice to do that, reason? Read below
          result = new item.constructor();
        } else {
          result = item;
        }
      }
    } else {
      result = item;
    }
  }

  return result;
}

init();

export {
  getLongname,
  getShortname,
  disableShortnames,
  mapToLowerCase,
  createTransferObject,
  createFromTransferObject,
  clone,
  initShortnamesToLongamesMap,
  shortnamesToLongnamesMap,
  longNamesToShortNamesMap,
};

import { TrackerImpressionMonitor } from './index.js';
import { Inject, Service } from 'typedi';
import { InjectionTokens } from '../di/container.js';
import type { Impressions, Tracker } from '../typings/index.js';

@Service()
class TrackerImpressionSDK implements Impressions.SDK {
  constructor(
    @Inject(InjectionTokens.Tracker) private readonly _tracker: Tracker.Tracker,
    private readonly _monitor: TrackerImpressionMonitor
  ) {}

  /**
   * @deprecated Use the SDK methods and not the monitor itself
   */
  get monitor(): TrackerImpressionMonitor {
    return this._monitor;
  }

  initialize(params: Impressions.InitializationParameters): void | undefined {
    this._tracker.initialized = {
      ...(this._tracker.initialized ?? {}),
      trackImpression: true,
    };
    this._monitor.start(params);
  }

  stop() {
    this._monitor.stop();
  }

  pause() {
    this._monitor.pause();
  }

  resume() {
    this._monitor.resume();
  }
}

export { TrackerImpressionSDK };

import { tracker } from '../initoftrackerobj.js';
import { Container, Service } from 'typedi';
import { InjectConfig } from '../di/container.js';
import type { Debug, Tracker } from '../typings/index.js';

@Service()
class TrackerDebugger implements Debug.Debugger {
  constructor(
    @InjectConfig() private readonly _config: Tracker.TrackerConfig
  ) {}

  log(...args: unknown[]) {
    if (this._config.debug) {
      // eslint-disable-next-line prefer-spread
      console.log.apply(console, args);
    }
  }

  error(error: Error) {
    if (this._config.debug) {
      console.error(error);
    }
  }
}

const debug = Container.get(TrackerDebugger);
tracker.log = (...args) => debug.log(args);
tracker.error = (error) => debug.error(error);

tracker.debugger = debug;

export { TrackerDebugger };

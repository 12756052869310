import { tracker } from '../initoftrackerobj.js';
import { Container, Service } from 'typedi';
import { InjectDocument } from '../di/container.js';
import type { Cookies } from '../typings/index.js';

@Service()
class CookiesService implements Cookies.Cookies {
  constructor(@InjectDocument() private readonly document: Document) {}

  setCookie<T = string | object>(
    cookieName: string,
    cookieValue: T,
    expirationInDays?: number,
    domainName?: string | null,
    sameSite?: 'None' | 'Lax' | 'Strict',
    isSecure = false
  ) {
    const d = new Date();
    const days =
      expirationInDays && !isNaN(expirationInDays) ? expirationInDays : 1;
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    if (!domainName) {
      this.document.cookie = `${cookieName}=${
        typeof cookieValue === 'string'
          ? cookieValue
          : JSON.stringify(cookieValue)
      }; path=/;${expires};`;
    } else {
      this.document.cookie = `${cookieName}=${cookieValue};${
        sameSite && sameSite?.length ? 'SameSite=' + sameSite + ';' : ''
      }${
        isSecure === true ? 'Secure;' : ''
      } path=/; domain=${domainName}; ${expires};`;
    }
  }

  getCookie(cookieName: string) {
    const name = cookieName + '=';
    const ca = this.document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) == 0) {
        const value = c.substring(name.length, c.length);
        return value && value?.length > 0 ? value : undefined;
      }
    }
    return undefined;
  }

  removeCookie(cname: string) {
    this.document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

tracker.cookies = Container.get(CookiesService);

export { CookiesService };

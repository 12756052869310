import type { PushFromDataLayerAction } from '../gtm/gtm.actions.js';
import { selectCookieAndSessionIdFromDataLayer } from '../gtm/gtm.selectors.js';
import { put, type PutEffect, takeEvery } from 'redux-saga/effects';
import { CIAMActions } from './ciam.slice.js';
import { GTMActions } from '../gtm/gtm.slice.js';
import type { SetCookieAndSessionId } from './ciam.actions.js';

function* getCookieAndSessionIdFromDataLayer(
  action: PushFromDataLayerAction
): Generator<PutEffect<SetCookieAndSessionId>> {
  const dataLayer = action.payload.dataLayer;
  const obj = selectCookieAndSessionIdFromDataLayer({ dataLayer });
  yield put(CIAMActions.setCookieIdAndSessionId(obj));
}

function* CIAMSaga() {
  yield takeEvery(
    GTMActions.pushFromDataLayer.type,
    getCookieAndSessionIdFromDataLayer
  );
}

export { getCookieAndSessionIdFromDataLayer, CIAMSaga };

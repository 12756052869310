import type { Impressions } from '../typings';
import type { InitializationParameters } from '../typings/impressions';
import { generateGuid } from '../utils/guid';

type Article = {
  id: string | null;
  href: string | null;
};

class ArticleInfo {
  constructor(
    public id: string | null = null,
    public href: string | null = null
  ) {}

  from(articleElement: HTMLElement): Article {
    this.id = articleElement.getAttribute('tentacle-id') ?? null;
    this.href = articleElement.getAttribute('href') ?? null;

    if (this.id === null) {
      this.id = this.id ?? this.getIdFromHref(this.href);
    }

    // BLUE - check for dmf code > trim everything after -
    if (this.id !== null && this.id?.includes('dmf')) {
      this.id = this.id.split('-')[0];
    }

    return this;
  }

  private getIdFromHref(href: string | null) {
    // BLUE
    // '/cnt/dmf20241104_95175236'
    // CUE
    // 'https://brandsite.be/section/title-of-the-article/878231.html'

    // Article id is available in the url, either as dmf number [BLUE] or articleid.html [CUE]
    if (href != null) {
      const dmfIndex = href.indexOf('dmf');
      if (dmfIndex > -1) {
        // BLUE
        return href.substring(dmfIndex, href.length);
      } else {
        // CUE
        const arr = href.split('/');
        const lastPart = arr[arr.length - 1];
        const htmlIndex = lastPart.indexOf('.html');
        return lastPart.substring(0, htmlIndex);
      }
    } else {
      return null;
    }
  }
}

type Headline = {
  size: string | null;
  text: string | null;
};

class HeadlineInfo {
  constructor(
    public size: string | null = null,
    public text: string | null = null
  ) {}

  from(target: HTMLElement): Headline {
    const headlines = target.querySelectorAll(
      'h1, h2, h3, h4, h5, h6, h7, h8, h9, h10'
    );

    if (headlines.length === 0) {
      this.size = null;
      this.text = null;
      return this;
    }

    this.size = headlines[0].getAttribute?.('data-headingsize') ?? null;
    this.text = headlines[0].getAttribute?.('data-headingtext') ?? null;

    return this;
  }
}

type Image = {
  url: string | null;
  width: number | null;
  height: number | null;
};

class ImageInfo {
  constructor(
    public url: string | null = null,
    public width: number | null = 0,
    public height: number | null = 0
  ) {}

  from(target: HTMLElement): Image {
    // Check if the image is not the icon
    const images = target.getElementsByTagName('img');
    // Check if an image element is available
    if (images.length > 0) {
      let imageElement = images[0];
      const testid = imageElement
        ? imageElement.getAttribute('data-testid')
        : null;

      if (testid !== null && testid.indexOf('icon') >= 0) {
        imageElement = images[1];
      }

      const getNumericAttribute = (
        key: string,
        element?: HTMLElement
      ): number | null => {
        if (!element) {
          return null;
        }

        const attribute = element.getAttribute(key);
        if (!attribute) {
          return null;
        }

        const result = parseInt(attribute);
        if (isNaN(result)) {
          return null;
        }

        return result;
      };

      this.url = imageElement ? imageElement.getAttribute('src') : null;

      this.height =
        getNumericAttribute('height', imageElement) ?? target.offsetHeight;

      this.width =
        getNumericAttribute('width', imageElement) ?? target.offsetWidth;
    }

    return this;
  }
}

export class Impression {
  impressionId = generateGuid();
  eventAction = 'show';
  eventcategory = 'impression';
  image?: Image;
  headline?: Headline;
  article?: Article;

  constructor(
    private target: HTMLElement,
    private params: InitializationParameters
  ) {}

  withId(value: string) {
    this.impressionId = value;
    return this;
  }

  withEventAction(value: 'show' | 'click') {
    this.eventAction = value;
    return this;
  }

  withImage(value: Image) {
    this.image = value;
    return this;
  }

  withHeadline(value: Headline) {
    this.headline = value;
    return this;
  }

  withArticle(value: Article) {
    this.article = value;
    return this;
  }

  build(): Impressions.ImpressionObject {
    const imageInfo = new ImageInfo();
    const headlineInfo = new HeadlineInfo();
    const articleInfo = new ArticleInfo();

    const image = this.image ?? imageInfo.from(this.target);
    const headline = this.headline ?? headlineInfo.from(this.target);
    const article = this.article ?? articleInfo.from(this.target);

    return {
      impressionId: this.impressionId,
      sessionId: this.params.sessionId,
      brandCode: this.params.brandCode,
      application: this.params.application,
      environment: this.params.environment,
      eventAction: this.eventAction,
      eventcategory: this.eventcategory,
      eventLabel: this.target.getAttribute('data-teaser-type') ?? null,
      origin: {
        box: {
          // CUE specific
          id: this.target.getAttribute('box-id') ?? null,
          // Set to null for POC
          position: {
            x: null,
            y: null,
          },
          width: null,
          height: null,
        },
        url: '',
      },
      article: {
        id: article.id,
        url: article.href,
        // Set to null for POC
        position: {
          x: null,
          y: null,
        },
        icon:
          this.target.children?.item(0)?.getAttribute('data-testid') ?? null,
        headline: {
          size: headline.size,
          text: headline.text,
        },
      },
      image: {
        url: image.url,
        width: image.width,
        height: image.height,
      },
    };
  }
}

import type { Views } from '../typings/index.js';
import { ViewTracker } from './views-tracker.js';
import { Service } from 'typedi';

/**
 * Views SDK is a service that provides methods to track views
 * into the GLiTR systems. This service is typically used by
 * third party tag managers like GTM to track views into the
 * GLiTR systems.
 * This SDK is available under the _mhtracker.views namespace
 */
@Service()
export class ViewsSDK implements Views.SDK {
  constructor(private readonly viewsTracker: ViewTracker) {}

  /**
   * Method used to normalize the view object and track the view
   * into the GLiTR systems. Third party tag managers like GTM will
   * typically use this method first instead of trackView()
   * @param view
   */
  logView(view: Views.ViewObject) {
    this.viewsTracker.logView(view);
  }

  /**
   * Track a raw view object without any normalization or sanitization.
   * This method is typically used by the GLiTR systems to track views
   * and will not be used by third party tag managers like GTM.
   * @param view
   */
  trackView(view: Views.ViewObject) {
    this.viewsTracker.trackView(view);
  }
}

import { createSlice } from '@reduxjs/toolkit';
import type { CIAMState } from './ciam.state.js';
import type { SetCookieAndSessionId } from './ciam.actions.js';

export const CIAMSlice = createSlice({
  name: 'ciam',
  initialState: {
    cookieId: null,
    sessionId: null,
  } as CIAMState,
  reducers: {
    setCookieIdAndSessionId: (state, action: SetCookieAndSessionId) => {
      return {
        ...state,
        cookieId: action.payload.cookieId,
        sessionId: action.payload.sessionId,
      };
    },
  },
});

const CIAMActions = CIAMSlice.actions;
export { CIAMActions };

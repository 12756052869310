import { tracker } from '../initoftrackerobj.js';
import { Container } from 'typedi';
import { ViewTracker } from './views-tracker.js';
import type { ViewObject } from '../typings/views.js';

const viewsTracker = Container.get(ViewTracker);
tracker.views = viewsTracker;
tracker.initView = () => {
  if (tracker.initialized) {
    tracker.initialized.trackView = true;
  }
  tracker.detectTheoPlayer();
  tracker.detectYoutube();
};
tracker.logView = (view: ViewObject) => viewsTracker.logView(view);
tracker.trackView = (view: ViewObject) => viewsTracker.trackView(view);
tracker.getDocumentTitle = () => viewsTracker.getDocumentTitle();

import { tracker } from './initoftrackerobj.js';

function checkForVideo() {
  const video = document.getElementsByClassName('video video--vmma');
  if (video.length) {
    tracker.video = video[0] as typeof tracker.video;
    if (tracker.video) {
      tracker.video.hasVideo = true;
    }
    window.addEventListener('blur', function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (doc.activeElement.src && doc.activeElement.src.match(/vmma/)) {
        if (tracker.video) {
          tracker.video.played = true;
        }
        tracker.isPlayingVideo = true;
        tracker.startedPlayingVideoOn = new Date();
      }
    });
  }
}

checkForVideo();

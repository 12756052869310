import type { CIAM, Events, Tracker, Views } from '../typings/index.js';
import { Inject, Service } from 'typedi';
import { ReduxStore } from '../redux/index.js';
import { InjectionTokens } from '../di/container.js';
import { selectCookieAndSessionId } from '../redux/ciam/ciam.selectors.js';
import { generateGuid } from '../utils/guid.js';

@Service()
export class CIAMSDK implements CIAM.SDK {
  constructor(
    private readonly store: ReduxStore,
    @Inject(InjectionTokens.Tracker) private readonly tracker: Tracker.Tracker
  ) {}

  trackEvent(eventObj: Events.GLiTREventDTO) {
    // Get cookie id and sessionid from the GTM datalayer
    const { cookieId, sessionId } = selectCookieAndSessionId(
      this.store.getStore().getState().ciam
    );
    this.tracker.logEvent({
      ...eventObj,
      sessionId: sessionId ?? generateGuid(),
      cookieId: cookieId ?? generateGuid(),
    });
  }

  trackPageView(pageViewObj: Views.ViewObject): void {
    // Get cookie id and sessionid from the GTM datalayer
    const { cookieId, sessionId } = selectCookieAndSessionId(
      this.store.getStore().getState().ciam
    );
    this.tracker.logView({
      ...pageViewObj,
      sessionId: sessionId ?? generateGuid(),
      cookieId: cookieId ?? generateGuid(),
    });
  }
}

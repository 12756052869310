import {
  configureStore,
  type Store,
  Tuple,
  combineReducers,
} from '@reduxjs/toolkit';
import { CIAMSlice } from './ciam/ciam.slice.js';
import { GTMSlice } from './gtm/gtm.slice.js';
import { CIAMSaga } from './ciam/ciam.saga.js';
import type { AppState } from './state.js';
import { Service } from 'typedi';
import createSagaMiddleware from 'redux-saga';

@Service()
export class ReduxStore {
  private readonly store: Store;

  constructor() {
    const sagaMiddleware = createSagaMiddleware();

    this.store = configureStore({
      reducer: combineReducers({
        ciam: CIAMSlice.reducer,
        gtm: GTMSlice.reducer,
      }),
      middleware: () => new Tuple(sagaMiddleware),
    }) as Store<AppState>;

    sagaMiddleware.run(CIAMSaga);
  }

  getStore(): Store {
    return this.store;
  }
}

//we need to find another way to look for a page leave eventually store it in storage and send it
//on the next page visit
import { tracker } from './initoftrackerobj.js';

function pagehideHandler() {
  if (
    !tracker.config.trackHeartbeat ||
    !tracker.config.trackHeartbeat
      .latest /* -> heartbeat hasn't occured yet */ ||
    (new Date().getTime() - tracker.config.trackHeartbeat.latest) / 1000 >= 3
  ) {
    /* -> latest heartbeat happend more than 3 seconds ago */ tracker.handleHeartbeat();
    tracker.log('Page hide detected to ${window.location.href}');
  }
}

window.addEventListener('pagehide', pagehideHandler, false);

import { createSlice } from '@reduxjs/toolkit';
import type { GTMState } from './gtm.state.js';
import type { PushFromDataLayerAction } from './gtm.actions.js';

export const GTMSlice = createSlice({
  name: 'gtm',
  initialState: {
    dataLayer: [] as Record<string, unknown>[],
  },
  reducers: {
    syncDataLayer: (state) => {
      return state;
    },
    pushFromDataLayer: (state: GTMState, action: PushFromDataLayerAction) => {
      return {
        ...state,
        dataLayer: action.payload.dataLayer,
      };
    },
  },
});

const actions = GTMSlice.actions;
export { actions as GTMActions };

import { tracker } from '../initoftrackerobj.js';

function retry(name: string, numOfRetries: number, callback: () => boolean) {
  let detectionCounter = 0;

  function setTimerForVideo() {
    let timeout = 1000;
    let found = false;
    if (detectionCounter != 0) {
      timeout = 2000;
    }

    tracker.log(`check for ${name} `, detectionCounter);
    if (detectionCounter < numOfRetries && !found) {
      detectionCounter++;
      found = callback();
      if (!found) {
        setTimeout(() => setTimerForVideo(), timeout);
      }
    } else if (found) {
      tracker.log(`${name} found on page stopping detection`);
    } else {
      tracker.log(` ${name} not detected and detection stopped`);
    }
  }

  setTimerForVideo();
}

tracker.utils = {
  retry: retry,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  fromEvent,
  interval,
  map,
  merge,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { normalizeUrlPart } from '../utils/stringUtils.js';
import { generateGuid } from '../utils/guid.js';
import { mapToLowerCase } from '../utils/mapperUtils.js';
import { UnsupportedException } from '../exceptions/unsupported.exception.js';
import { Service } from 'typedi';
import {
  InjectConfig,
  InjectDocument,
  InjectTracker,
  InjectWindow,
} from '../di/container.js';
import { TrackerStorage } from '../utils/localstorage.js';
import { TrackerDebugger } from '../utils/debug.js';
import { PubSubService } from '../pubsub/pubsub.service.js';
import { CookiesService } from '../utils/cookies.js';
import type { Impressions, Tracker } from '../typings/index.js';
import { TrackerClient } from '../utils/tracker-client.js';
import { PromotionMonitor } from './promotion-monitor.js';
import { Impression } from './impression.js';

@Service()
class TrackerImpressionMonitor {
  private destroy$ = new Subject<void>();
  private initialisationParameters?: Impressions.InitializationParameters;

  constructor(
    @InjectDocument() private readonly _document: Document,
    @InjectWindow() private readonly _window: Window,
    @InjectTracker() private readonly tracker: Tracker.Tracker,
    @InjectConfig() private readonly config: Tracker.TrackerConfig,
    private readonly store: TrackerStorage,
    private readonly debug: TrackerDebugger,
    private readonly pubsub: PubSubService,
    private readonly cookies: CookiesService,
    private readonly promotionMonitor: PromotionMonitor
  ) {}

  schedulePendingImpressions() {
    this.tracker.log('Sending impressions...');
    // Check if there are impressions in the sessionStorage
    const impressions =
      this.store.get<Array<Impressions.ImpressionObject>>('impressions') ?? [];
    if (impressions.length > 0) {
      this.trackImpression(impressions);
      this.store.set('impressions', []);
      this.tracker.log(
        'updated sessionStorage - sending impression (remaining: ' +
          impressions.length +
          ')'
      );
    } else {
      this.debug.log('No impressions to track, skipping...');
    }
  }

  startObservingTargets(params: Impressions.InitializationParameters) {
    // Define the target elements to which you want to add impression tracking.
    const targets = this._document.querySelectorAll<HTMLAnchorElement>(
      'a[data-testid=article-teaser]'
    );

    this.debug.log(targets);
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 1) {
            try {
              const impression = new Impression(
                entry.target as HTMLElement,
                params
              )
                .withEventAction('show')
                .build();

              this.promotionMonitor.mark(entry.target, impression.impressionId);

              // Add impression data to session storage impression array
              this.addToStore(impression);
              this.debug.log(
                'pushed data to sessionStorage: ' +
                  (
                    this.store.get<Array<Impressions.ImpressionObject>>(
                      'impressions'
                    ) ?? []
                  ).length +
                  1
              );
            } catch (err) {
              this.debug.error(
                new Error(`Could not construct impression data`, err as Error)
              );
            }
          }
        }),
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    // Link the observer to the targets
    targets.forEach((target) => {
      observer.observe(target);
      this.promotionMonitor.handleClick(target, params, this.destroy$);
    });
    this.debug.log('Listening for impressions in the window...');
  }

  // Add impression data to session storage impression array
  addToStore(impressionData: Impressions.ImpressionObject) {
    const impressions = this.getFromStore('impressions');
    this.store.set('impressions', [...(impressions ?? []), impressionData]);
  }

  // Get value from session storage
  getFromStore(id: string): Array<Impressions.ImpressionObject> | undefined {
    return this.store.get<Array<Impressions.ImpressionObject>>(id);
  }

  start(params: Impressions.InitializationParameters) {
    this.initialisationParameters = params;
    // Stop listening for events before registering new event listeners!
    this.debug.log('Stopping tracking observers...');
    this.stop();

    if (window.IntersectionObserver) {
      // Check the idle time
      const events$ = merge(
        fromEvent(this._document, 'mousemove'),
        fromEvent(this._document, 'keydown'),
        fromEvent(this._document, 'scroll'),
        fromEvent(this._document, 'click')
      );
      // Wait for all elements in the DOM to be loaded in before starting to observe targets
      from(
        this._document.readyState !== 'loading'
          ? of(true)
          : fromEvent(this._document, 'DOMContentLoaded')
      )
        .pipe(
          tap(() => this.startObservingTargets(params)),
          switchMap(() => events$),
          debounceTime(100), // Just to not overload our JS
          takeUntil(this.destroy$)
        )
        .subscribe();

      // Currently the navigation api is not widely supported
      // so the only way to reliably detect route changes is by polling the href
      const href$ = interval(100).pipe(
        map(() => this._window.location.href),
        distinctUntilChanged()
      );

      const visibility$ = fromEvent(this._document, 'visibilitychange').pipe(
        filter(() => this._document.visibilityState === 'hidden')
      );

      const idle$ = events$.pipe(debounce(() => interval(2000)));

      merge(href$, visibility$, idle$)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.schedulePendingImpressions());
    } else {
      this.debug.error(
        new UnsupportedException(
          'Could not start impression tracking, IntersectionObserver is not supported!'
        )
      );
    }
  }

  getUrl(config: Tracker.TrackerConfig, pathName?: string | null): string {
    if (config.spa) {
      const loc = window.location;
      const path = pathName ?? loc.pathname;
      const result =
        loc.protocol +
        '//' +
        loc.hostname +
        '/' +
        normalizeUrlPart(path) +
        (window.location.search ?? '');
      return normalizeUrlPart(result) as string;
    }
    return window.location.href;
  }

  logImpression(
    obj: Impressions.ImpressionObject
  ): Impressions.ImpressionObject {
    // Check if an impressionid was sent from the frontend - otherwise generate a UUID
    this.tracker.sharedState = {
      ...this.tracker.sharedState,
      sessionId: obj.sessionId ?? this.tracker.sharedState.sessionId,
      brandCode: obj.brandCode ?? this.tracker.sharedState.brandCode,
    };

    return {
      ...obj,
      sessionId: obj.sessionId ?? this.tracker.sharedState.sessionId,
      brandCode: obj.brandCode ?? this.tracker.sharedState.brandCode,
    };
  }

  buildPayload({
    sessionId,
    cookieId,
    impressionId,
    brandCode,
    application,
    environment,
    eventLabel,
    eventAction,
    eventcategory,
    origin,
    image,
    article,
    pageUrl,
    pageType,
  }: any): any {
    const data = {
      sessionId,
      impressionId,
      brandCode,
      application,
      environment,
      cookieId,
      clientTimestamp: new Date().getTime(),
      eventLabel,
      eventAction,
      eventcategory,
      origin,
      image,
      article,
    };

    data.origin = data.origin ? data.origin : {};
    data.origin.url = pageUrl;
    data.origin.pagetype = pageType;

    return data;
  }

  trackImpression(impressions: Impressions.ImpressionObject[]) {
    const cookieId: string =
      this.store.get('_mhtc_cId') ??
      this.cookies.getCookie('_mhtc_cId') ??
      generateGuid();

    this.debug?.log(cookieId);

    const impressionDtos = impressions.map(
      ({
        sessionId,
        impressionId,
        brandCode,
        application,
        environment,
        eventLabel,
        eventAction,
        eventcategory,
        origin,
        image,
        article,
        pathName,
        pageType,
      }) => {
        const pageUrl = this.getUrl(this.config, pathName);
        const data = this.buildPayload({
          sessionId,
          cookieId,
          impressionId,
          brandCode,
          application,
          environment,
          eventLabel,
          eventAction,
          eventcategory,
          origin,
          image,
          article,
          pageUrl,
          pageType,
        });
        return mapToLowerCase(data);
      }
    );

    const body = JSON.stringify(impressionDtos);

    this.pubsub.notifySubscribers('mhtracker/trackimpression', impressionDtos);
    const impressionsTrackerClient = new TrackerClient(
      this.config.impressionApi,
      this.debug
    );
    impressionsTrackerClient.withContentType('application/json').post(body);
  }

  stop() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject();
  }

  pause() {
    this.stop();
  }

  resume() {
    if (!this.initialisationParameters)
      throw new Error(
        'Impression SDK is not initialized, cannot resume. Please call the initialise method first.'
      );

    this.start(this.initialisationParameters);
  }
}

export { TrackerImpressionMonitor };

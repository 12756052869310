import { Container, Inject } from 'typedi';
import { tracker } from '../initoftrackerobj.js';

export const InjectionTokens = {
  Tracker: 'tracker',
  Document: 'document',
  localStorage: 'localStorage',
  TrackerConfig: 'trackerConfig',
  window: 'window',
};

export function InjectConfig() {
  return Inject(InjectionTokens.TrackerConfig);
}

export function InjectLocalStorage() {
  return Inject(InjectionTokens.localStorage);
}

export function InjectWindow() {
  return Inject(InjectionTokens.window);
}

export function InjectDocument() {
  return Inject(InjectionTokens.Document);
}

export function InjectTracker() {
  return Inject(InjectionTokens.Tracker);
}

Container.set(InjectionTokens.Tracker, tracker);
Container.set(InjectionTokens.Document, window.document);
Container.set(InjectionTokens.localStorage, window.localStorage);
Container.set(InjectionTokens.window, window);
Container.set(InjectionTokens.TrackerConfig, tracker.config);
Container.set(InjectionTokens.window, window);

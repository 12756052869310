import { tracker } from '../initoftrackerobj.js';
import { Service } from 'typedi';

@Service()
export class PubSubService {
  notifySubscribers(eventName: string, obj: object) {
    if (
      window.MEDIAHUIS &&
      window.MEDIAHUIS.pubsub &&
      window.MEDIAHUIS.pubsub.publish
    ) {
      tracker.log('notifiysubscribers of mh', eventName, obj);
      window.MEDIAHUIS.pubsub?.publish?.(eventName, obj);
    }
    const event = new CustomEvent(eventName, { detail: obj });
    window.dispatchEvent(event);
  }
}

import { tracker } from './initoftrackerobj.js';
import { fromEvent, merge, Observable, takeUntil, throttleTime } from 'rxjs';

function handleEvent() {
  tracker.wasActiveOn = new Date();
}

if (tracker.config.heartbeatEnabled) {
  const eventsThatShouldBeThrottled$: Observable<Event> = merge(
    fromEvent(document, 'mousemove'),
    fromEvent(document, 'scroll'),
    fromEvent(document, 'touchmove')
  ).pipe(throttleTime(1000));

  const eventsThatShouldNotBeThrottled$: Observable<Event> = merge(
    fromEvent(document, 'click'),
    fromEvent(document, 'focus'),
    fromEvent(document, 'blur'),
    fromEvent(document, 'keydown')
  );

  merge(eventsThatShouldBeThrottled$, eventsThatShouldNotBeThrottled$)
    .pipe(takeUntil(fromEvent(window, 'unload')))
    .subscribe(() => handleEvent());
}

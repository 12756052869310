import { Service } from 'typedi';
import type { Impressions } from '../typings';
import { TrackerStorage } from '../utils/localstorage';
import { Impression } from './impression';
import type { InitializationParameters } from '../typings/impressions';
import { fromEvent, map, filter, takeUntil, Subject } from 'rxjs';

@Service()
export class PromotionMonitor {
  constructor(private readonly store: TrackerStorage) {}

  /**
   * mark element with data-impression-id
   * to create a new impression with the same id in localstorage when clicked
   */
  mark(target: Element, impressionId: string) {
    target.setAttribute('data-impression-id', impressionId);
  }

  handleClick(
    target: Element,
    params: InitializationParameters,
    destroy$: Subject<void>
  ) {
    fromEvent(target, 'click')
      .pipe(
        map((e: Event) => {
          return e.currentTarget;
        }),
        filter(
          (value: EventTarget | HTMLElement | null): value is HTMLElement => {
            return value !== null;
          }
        ),
        map((value) => {
          return value.getAttribute('data-impression-id');
        }),
        filter((value: string | null): value is string => value !== null),
        takeUntil(destroy$)
      )
      .subscribe((impressionId) => {
        const impression = new Impression(target as HTMLElement, params)
          .withId(impressionId)
          .withEventAction('click')
          .build();

        const impressions =
          this.store.get<Array<Impressions.ImpressionObject>>('impressions') ??
          [];

        return this.store.set('impressions', [...impressions, impression]);
      });
  }
}

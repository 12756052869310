import type { Selector } from '@reduxjs/toolkit';
import type { GTMState } from './gtm.state.js';

const selectCookieAndSessionIdFromDataLayer: Selector<
  GTMState,
  { cookieId: string; sessionId: string }
> = (state) => {
  const lastEntryWithCookieId = state.dataLayer?.findLast(
    (item) => Object.keys(item).indexOf('cookieid') > -1
  );
  const lastEntryWithSessionId = state.dataLayer?.findLast(
    (item) => Object.keys(item).indexOf('sessionid') > -1
  );

  return {
    cookieId: (lastEntryWithCookieId?.['cookieid'] as string) ?? null,
    sessionId: (lastEntryWithSessionId?.['sessionid'] as string) ?? null,
  };
};

export { selectCookieAndSessionIdFromDataLayer };

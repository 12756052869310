import { tracker } from './initoftrackerobj.js';
import type { Tracker } from './typings/index.js';

function initializeConfiguration(tracker: Tracker.Tracker) {
  const config: Tracker.TrackerConfig = {
    ...(tracker.config ?? {}),
    api: tracker.config?.api ?? '',
    debug:
      tracker.config?.debug ??
      tracker.cookies.getCookie('_mhtrdebug') === '1' ??
      false,
    viewApi: tracker.config?.viewApi ?? tracker.config?.api,
    eventApi: tracker.config?.eventApi ?? tracker.config?.api,
    heartbeatApi: tracker.config?.heartbeatApi ?? tracker.config?.api,
    impressionApi: tracker.config?.impressionApi ?? tracker.config?.api,
    watchSpeed: tracker.config?.watchSpeed || 1500,
    maxTotalAttentionSpan: tracker.config?.maxTotalAttentionSpan ?? -1,
    spa: tracker.config?.spa ?? false,
    heartbeatEnabled: tracker.config?.heartbeatEnabled ?? true, // TODO is this correct
    triggerView: tracker.config?.triggerView ?? true,
    isCalledFromSdk: tracker.config?.isCalledFromSdk ?? false,
  };

  config.disableShortnames = shouldDisableShortNames(config.viewApi);
  config.disableshortnamesfromcode = `set ${
    config.disableShortnames ? 'true' : 'false'
  } from code`;

  function shouldDisableShortNames(viewApi?: string): boolean {
    switch (viewApi) {
      case '//preview.mhtr.be/':
      case '//preview.mhtr.be':
      case '/preview.mhtr.be/':
      case '/preview.mhtr.be':
      case '//test.mhtr.be':
      case '//test.mhtr.be/':
      case '/test.mhtr.be':
      case '/test.mhtr.be/':
      case '//prod.mhtr.be/':
      case '//prod.mhtr.be':
      case '/prod.mhtr.be/':
      case '/prod.mhtr.be':
        return false;
      default:
        return true;
    }
  }

  return config;
}

tracker.config = initializeConfiguration(tracker);
tracker.config.scriptVersion = '<BUILD_VERSION_PLACEHOLDER>'; //Do not edit, this will be replaced at compile-time

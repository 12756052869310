import { EventsTracker } from './events-tracker.js';
import { EventsSDK } from './events-sdk.js';
import type { Events } from '../typings/index.js';

/**
 * @class EventsApiSDK
 * @description Public entrypoint to send Events to the GLiTR API for usage in NodeJS
 */
export class EventsApiSDK {
  private readonly eventsSDK: EventsSDK;

  /**
   * @constructor
   * @param eventApi The api endpoint to send events to, defaults to https://test.mhtr.be/next/e
   * @param scriptVersion The script version that is installed on the server, see package.json
   */
  constructor(eventApi: string, scriptVersion: string) {
    this.eventsSDK = new EventsSDK(
      new EventsTracker(() => ({
        eventApi,
        scriptVersion,
      }))
    );
  }

  logEvent(dto: Events.GLiTREventDTO) {
    return this.eventsSDK.logEvent(dto);
  }

  trackEvent(event: Events.GLiTREvent) {
    return this.eventsSDK.trackEvent(event);
  }
}

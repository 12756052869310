import type { Video } from './typings/index.js';

(function (win: Window) {
  const tracker = win._mhtracker;
  const analyticCodes: { [key: string]: string } = {
    'nieuwsblad.be': 'UA-52716515-6',
    'gva.be': 'UA-52716515-9',
    'hbvl.be': 'UA-52716515-8',
    'standaard.be': 'UA-52716515-7',
    'telegraaf.nl': 'UA-52716515-13',
    'vrouw.nl': 'UA-52716515-14',
    'metronieuws.nl': 'UA-52716515-15',
    'limburger.nl': 'UA-52716515-12',
  };
  const rootHostname: string = document.location.hostname
    .toLowerCase()
    .split('.')
    .splice(1)
    .join('.');
  let gaId = analyticCodes[rootHostname];

  if (!gaId) {
    gaId = analyticCodes[document.location.hostname.toLowerCase()];
  }

  const alreadyTrackedVideos: { [key: string]: boolean } = {};

  function trackEvent(
    documentTitle: string,
    originalVideoId: string,
    target: Video.VideoTarget
  ) {
    let newVideoId;
    if (!gaId) {
      tracker.log('ga youtube video tracking disabled for ', rootHostname);
      return;
    }

    if (target.getVideoData) {
      newVideoId = target.getVideoData();
    }

    if (!tracker.videoInitialized) {
      if (win.ga) {
        win.ga('create', gaId, 'auto');
      }
      tracker.videoInitialized = true;
    }

    const event_category = 'Youtube Video Events';
    const event_action = 'firstplay'; //first play, played
    const event_label = documentTitle + ' (' + originalVideoId + ')';

    if (originalVideoId !== newVideoId && newVideoId) {
      tracker.log(
        'YOUTUBE: Video will NOT be tracked because the video comes from the youtube choice list',
        event_category,
        event_action,
        event_label
      );
      return;
    }

    if (alreadyTrackedVideos[originalVideoId]) {
      tracker.log(
        'YOUTUBE: Call NOT done because already fired',
        event_category,
        event_action,
        event_label
      );
    } else {
      if (win.ga) {
        win.ga('send', 'event', event_category, event_action, event_label, 1, {
          nonInteraction: 1,
        });
      }
      tracker.log(
        'YOUTUBE: Fire youtube tracking',
        event_category,
        event_action,
        event_label
      );
      alreadyTrackedVideos[originalVideoId] = true;
    }
  }

  const videoTracker = { trackYoutubePlay: trackEvent };
  win._mhtracker.videoTracker = videoTracker;
})(window as Window);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { tracker } from './initoftrackerobj.js';

declare const YT: any;

tracker.mh_videos = [];
tracker.mh_players = [];

/**
 * This is where the custom code gets hooked in. The youtube player will emit following events:
 *  - mh-youtube-paused
 *  - mh-youtube-played
 *  - mh-youtube-ended
 *
 * Naming should speak for itself. Any other trackers can just listen to these events without initializing a player.
 */
const videoTimes: { [videoId: string]: number } = {};
let readyEventFired = false;

window.addEventListener('mh-youtube-ready', function (e: any) {
  //tracker.log("youtube video youtube ready");
  tracker.video = tracker.video || {};
  videoTimes[e.detail.videoId] = 0;
  tracker.video.furthestPlay = 0;
  tracker.video.hasVideo = true;
  if (!readyEventFired) {
    setFurthestPlay(e.detail.YTEvent.target, e.detail.videoId);
    readyEventFired = true;
  }
});

const addEventListeners = () => {
  window.addEventListener('mh-youtube-paused', () => {
    //tracker.log("youtube video paused");
    tracker.video = tracker.video || {};

    tracker.video.paused = true;
    tracker.isPlayingVideo = false;
  });

  window.addEventListener('mh-youtube-played', (e: any) => {
    //tracker.log("video played");
    tracker.video = tracker.video || {};
    tracker.video.played = true;
    tracker.isPlayingVideo = true;
    tracker.startedPlayingVideoOn = new Date();
    try {
      tracker.videoTracker.trackYoutubePlay(
        tracker.getDocumentTitle(),
        e.detail.videoId,
        e.detail.YTEvent.target
      );
    } catch (e) {
      tracker.log('error during ga youtube tracking', e);
    }
  });

  window.addEventListener('mh-youtube-ended', () => {
    if (tracker.video) {
      tracker.video.ended = true;
    }
    tracker.isPlayingVideo = false;
  });
};

function setFurthestPlay(player: any, videoId: string) {
  const timeInMs = 2 * 1000;
  function calculateTime() {
    if (tracker.isPlayingVideo) {
      const timePlayed = player.getCurrentTime();
      if (timePlayed > 0) {
        videoTimes[videoId] = timePlayed;
      }
    }
    tracker.video = tracker.video || {};
    tracker.video.furthestPlay = Object.values(videoTimes ?? []).reduce(
      (x, y) => x + y,
      0
    );
    tracker.log('video playing: ', tracker.video.furthestPlay);
    setTimeout(calculateTime, timeInMs);
  }

  setTimeout(calculateTime, timeInMs);
}

function _detectYoutube() {
  //tracker.log("detectYoutube - check if iframe is present");
  const frames = Array.prototype.slice.call(
    document.querySelectorAll('iframe')
  );
  let found = false;

  if (
    frames &&
    frames.filter((frame) => frame.src.indexOf('youtube.') > -1).length > 0
  ) {
    addEventListeners();
    addYoutubeApiscript();
    for (let i = 0; i < frames.length; i++) {
      const frame = frames[i];
      if (
        frame.src &&
        frame.src.match(
          /(?:https?:)?\/\/www\.youtube\.com\/embed\/([\w-]{11})(\?)?/
        )
      ) {
        let queryString = [];
        if (!frame.src.match(/enablejsapi=1/))
          queryString.push('enablejsapi=1');
        if (!frame.src.match(/origin=.*/))
          queryString.push(
            'origin=' +
              window.location.protocol +
              '//' +
              window.location.hostname
          );
        if (queryString.length) {
          queryString = queryString.join('&') as any;
          if (frame.src.indexOf('?') >= 0) {
            frame.src = frame.src + '&' + queryString;
          } else {
            frame.src = frame.src + '?' + queryString;
          }
        }
        found = true;
        //tracker.log("detectYoutube - youtube frame found: " + found);
        tracker.mh_videos.push(frame);
      }
      //tracker.log("detectYoutube - iframe is not youtube");
    }

    tracker.video = {
      videoIds: [],
      furthestPlay: 0,
      played: null,
      paused: null,
      ended: null,
      startedPlayingAfter: 0,
    };

    //tracker.log("detectYoutube - tracker.video was reset");
  }
  //tracker.log("detectYoutube - return found = " + found);
  return found;
}

function addYoutubeApiscript() {
  if (!document.getElementById('youtubeEnableAPI')) {
    const enableAPI = document.createElement('script');
    enableAPI.setAttribute('id', 'youtubeEnableAPI');
    enableAPI.setAttribute('src', 'https://www.youtube.com/iframe_api');
    const firstScriptTag = document.getElementsByTagName('script')[0];
    if (firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(enableAPI, firstScriptTag);
    }
  }
}

function getVideoIdFromYoutubeFrame(frame: any) {
  return frame.src.match(
    /(?:https?:)?\/\/www\.youtube\.com\/embed\/([\w-]{11})(\?)?/
  )[1];
}

window.onYouTubeIframeAPIReady = () => {
  for (let i = 0; i < tracker.mh_videos.length; i++) {
    tracker.mh_players[i] = new YT.Player(tracker.mh_videos[i], {
      events: {
        onReady: (event: any) => {
          tracker.log('youtube ready event fired');
          const globalEvent = document.createEvent('CustomEvent');
          globalEvent.initCustomEvent('mh-youtube-ready', true, true, {
            videoId: getVideoIdFromYoutubeFrame(tracker.mh_videos[i]),
            YTEvent: event,
            player: tracker.mh_players[i],
          });
          window.dispatchEvent(globalEvent);
        },
        onStateChange: (event: any) => {
          const globalEvent = document.createEvent('CustomEvent');
          if (event) {
            tracker.log('youtube onstate change', event.data);
          }

          switch (event.data) {
            case -1:
              globalEvent.initCustomEvent('mh-youtube-ready', true, true, {
                videoId: getVideoIdFromYoutubeFrame(tracker.mh_videos[i]),
                YTEvent: event,
              });
              window.dispatchEvent(globalEvent);
              break;
            case 2:
              globalEvent.initCustomEvent('mh-youtube-paused', true, true, {
                videoId: getVideoIdFromYoutubeFrame(tracker.mh_videos[i]),
                YTEvent: event,
              });
              window.dispatchEvent(globalEvent);
              break;
            case 1:
              globalEvent.initCustomEvent('mh-youtube-played', true, true, {
                videoId: getVideoIdFromYoutubeFrame(tracker.mh_videos[i]),
                YTEvent: event,
              });
              window.dispatchEvent(globalEvent);
              break;
            case 0:
              globalEvent.initCustomEvent('mh-youtube-ended', true, true, {
                videoId: getVideoIdFromYoutubeFrame(tracker.mh_videos[i]),
                YTEvent: event,
              });
              window.dispatchEvent(globalEvent);
              break;
          }
        },
      },
    });
  }
};
tracker.detectYoutube = _detectYoutube;

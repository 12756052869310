import type { GTM } from '../typings/index.js';
import { GTMDayaLayerService } from './gtm-datalayer.js';
import { Service } from 'typedi';

@Service()
export class GTMSDK implements GTM.SDK {
  constructor(private readonly GTMDataLayer: GTMDayaLayerService) {}

  observeDataLayer(): void {
    this.GTMDataLayer.startObservingDataLayer();
  }

  stopObservingDataLayer() {
    this.GTMDataLayer.stopObservingDataLayer();
  }
}

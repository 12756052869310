import { tracker } from './initoftrackerobj.js';

const hiddenSupport = document && document.visibilityState;

function handleVisibilityChange() {
  if (document.hidden) {
    tracker.isAlive = false;
  } else {
    tracker.isAlive = true;
  }
}

if (hiddenSupport) {
  document.addEventListener('visibilitychange', handleVisibilityChange, false);
}

import type { Tracker } from '../typings/index.js';

function getBody(config: Partial<Tracker.TrackerConfig>, data: object) {
  let body = 'data=' + encodeURIComponent(JSON.stringify(data));
  body += '&url=' + encodeURIComponent(window.location.href);
  body +=
    '&scriptVersion=' + encodeURIComponent(config.scriptVersion ?? 'develop');

  return body;
}

export { getBody };

import type { CIAMState } from './ciam.state.js';
import type { Selector } from '@reduxjs/toolkit';

export const selectCookieAndSessionId: Selector<
  CIAMState,
  { cookieId?: string | null; sessionId?: string | null }
> = (state) => {
  return {
    cookieId: state.cookieId,
    sessionId: state.sessionId,
  };
};

import { tracker } from './initoftrackerobj.js';

const detector = new tracker.BlockAdBlock({
  debug: false,
  checkOnLoad: false,
  resetOnEnd: false,
});

tracker.adBlockers = tracker.adBlockers || {};
tracker.adBlockers.adblockSP = false;
tracker.adBlockers.adblock = false;
tracker.adBlockers.adblockWebcms = false;

detector.check(true);
detector.onDetected(function () {
  tracker.adBlockers.adblock = true;
});

if (
  window.MEDIAHUIS &&
  window.MEDIAHUIS.config &&
  window.MEDIAHUIS.config.adblockSP
) {
  tracker.adBlockers.adblockSP =
    window.MEDIAHUIS.config.adblockSP <= 0 ? false : true;
}
document.addEventListener('sp.blocking', function () {
  tracker.adBlockers.adblockSP = true;
});

if (
  window.MEDIAHUIS &&
  window.MEDIAHUIS.config &&
  window.MEDIAHUIS.config.adblock
) {
  tracker.adBlockers.adblockWebcms =
    window.MEDIAHUIS.config.adblock <= 0 ? false : true;
}
document.addEventListener('adblock.changed', function () {
  if (window.MEDIAHUIS && window.MEDIAHUIS.config) {
    tracker.adBlockers.adblockWebcms =
      window.MEDIAHUIS.config.adblock <= 0 ? false : true;
  }
});

import type { Events } from '../typings/index.js';
import { EventsTracker } from './events-tracker.js';

/**
 * Public entrypoint to send Events to the GLiTR API
 */
class EventsSDK {
  constructor(private readonly eventsTracker: EventsTracker) {}

  /**
   * Normalizes the event object by generating an event ID if it is not provided
   * and sets any extra properties present on the dto object on the 'other' property.
   * This method will then send this event to the GLiTR API
   * @param dto GLiTREventDTO
   */
  logEvent(dto: Events.GLiTREventDTO) {
    const event = this.eventsTracker.normalizeEventObject(dto);
    const data = this.eventsTracker.constructEventPayload(event);
    this.eventsTracker.sendPayload(data);
  }

  /**
   * Sends an event to the GLiTR API
   * This method will sanitize and parse the event object to ensure it is in the correct format
   * to be sent to the GLiTR API
   * @param event The event data to be sent to the GLiTR API
   */
  trackEvent(event: Events.GLiTREvent) {
    const data = this.eventsTracker.constructEventPayload(event);
    this.eventsTracker.sendPayload(data);
  }
}

export { EventsSDK };

import { tracker } from './initoftrackerobj.js';
import { TrackerImpressionSDK } from './impressions/impression-sdk.js';
import { Container } from 'typedi';
import type { Impressions } from './typings/index.js';

const sdk = Container.get(TrackerImpressionSDK);
tracker.impressions = sdk;

tracker.logImpression = (data: Impressions.ImpressionObject) =>
  sdk.monitor.trackImpression([sdk.monitor.logImpression(data)]);
tracker.trackImpression = (data: Impressions.ImpressionObject) =>
  sdk.monitor.trackImpression([data]);
tracker.initImpression = () => {
  tracker.log('track impression initialized');
  tracker.initialized = {
    ...(tracker.initialized ?? {}),
    trackImpression: true,
  };
};

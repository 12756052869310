/* eslint-disable @typescript-eslint/no-explicit-any */
import { tracker } from '../initoftrackerobj.js';
import type { Utils } from '../typings/index.js';

function trim(s: string, c: string): string {
  if (c === ']') c = '\\]';
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '');
}

function normalizeUrlPart(
  part?: string | null | undefined
): string | undefined | null {
  if (typeof part !== 'string') {
    return part;
  }
  return trim(part, '/');
}

function getQueryString(url: string): Utils.QueryString {
  const parser = document.createElement('a'),
    searchObject = {};
  let split: any;

  // Let the browser do the work
  parser.href = url;

  // Convert query string to object
  const queries = parser.search.replace(/^\?/, '').split('&');
  for (let i = 0; i < queries.length; i++) {
    split = queries[i].split('=');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    searchObject[split[0]] = split[1];
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash,
  };
}

function parseUrl(url: string): Utils.QueryString | URL | null | undefined {
  if (!url) {
    return null;
  }

  if (window.URL) {
    // Doesn't work in IE > will give console error & prevent tracking from being fired
    //return new URL(url);

    let result;

    try {
      result = new URL(url);
    } catch (e) {
      result = getQueryString(url);
    }

    return result;
  } else if (document && document.createElement) {
    const parser = document.createElement('a');
    const searchObject = {};
    let split, i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    const queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
      split = queries[i].split('=');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      searchObject[split[0]] = split[1];
    }
    return {
      protocol: parser.protocol,
      host: parser.host,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      searchObject: searchObject,
      hash: parser.hash,
    };
  }

  return null;
}

function getWildcardDomain(url?: string | null): string | null | undefined {
  let start = 0;
  let normalized = '';

  if (typeof url !== 'string') {
    return url;
  }

  if (url.length > 0) {
    if (url.indexOf('http://') > -1) {
      start = url.indexOf('http://') + 7;
    }
    if (url.indexOf('https://') > -1) {
      start = url.indexOf('https://') + 8;
    }
    if (url[0] === '/') {
      start = 1;
    }
  }

  let startPart = url.substr(start);

  // Check domain
  const arr = startPart.split('.');

  if (arr.length > 0) {
    switch ((arr[0] || '').toLowerCase()) {
      case 'www':
      case 'm':
      case 'digikrant':
      case 'preview':
      case 'beta-acc':
      case 'vrouwacc':
      case 'previewm':
      case 'test':
      case 'hotfix':
      case 'testm':
      case 'hotfixm':
      case 'preview2':
        startPart = startPart.replace(arr[0] + '.', '');
        break;
    }
  }

  // Check if still contains "/"
  let end = startPart.indexOf('/');
  if (end == -1) {
    end = startPart.length - 1;
  }

  if (startPart.length > 0 && startPart[end] === '/') {
    normalized = startPart.substring(0, end);
  } else {
    normalized = startPart;
  }

  return normalized;
}

function getUrlParams<T = object>(url: string): T {
  const params: T = {} as T;

  if (url) {
    decodeURIComponent(window.location.search).replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str: string, key: string, value: unknown) {
        const v = encodeURIComponent((value as any).replace(/["'´`]+/g, ''));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params[key.toLowerCase()] = v;
        return v;
      }
    );
  }

  return params;
}

function isSameHostname(
  pageReferrerHost?: string,
  pageCurrentHost?: string
): boolean {
  const refererHost = (pageReferrerHost || '').toLowerCase();
  const currentHost = (pageCurrentHost || '').toLowerCase();

  return !!refererHost && !!currentHost && refererHost === currentHost;
}

tracker.stringUtils = {
  normalizeUrlPart: normalizeUrlPart,
  getWildcardDomain: getWildcardDomain,
  isSameHostname: isSameHostname,
  trim,
  parseUrl,
  getUrlParams,
};

export {
  trim,
  normalizeUrlPart,
  getQueryString,
  parseUrl,
  getWildcardDomain,
  getUrlParams,
  isSameHostname,
};

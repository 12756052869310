import { tracker } from './initoftrackerobj.js';

function callback(playerId: string) {
  const kdp = document.getElementById(playerId);
  tracker.video = tracker.video || {};
  tracker.video.furthestPlay = 0;
  tracker.video.hasVideo = true;
  if (!kdp) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  kdp.kBind('doPlay', function () {
    tracker.log('playing video kulatura');
    if (tracker.video) {
      tracker.video.played = true;
    }
    tracker.isPlayingVideo = true;
    tracker.startedPlayingVideoOn = new Date();
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  kdp.kBind('doPause', function () {
    tracker.log('playing video kulatura');
    if (tracker.video) {
      tracker.video.paused = true;
    }
    tracker.isPlayingVideo = false;
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  kdp.kBind('doStop', function () {
    if (tracker.video) {
      tracker.video.ended = true;
    }
    tracker.isPlayingVideo = false;
  });
}

function containsOnlyAdVideos() {
  // check if it's an actual video and not an 'in content' video Advertisement
  //var playerIds = Object.keys(window.kWidget.readyWidgets); // not always ready at page load
  const urls = Object.keys(window.kWidget.iframeUrls);
  const autoembed = Object.keys(window.kWidget.iframeAutoEmbedCache);
  const types = urls.concat(autoembed);
  return types
    .map((x) => x.match(/adh_incontentPlayer/))
    .every((v) => v !== null);
}

function _detectKaltura() {
  if (window.kWidget) {
    tracker.log(' kultura video found');
    if (!containsOnlyAdVideos()) {
      tracker.video = tracker.video || {};
      tracker.video.hasVideo = true;
      window.kWidget.addReadyCallback(callback);
      return true;
    }
  }
  return false;
}

tracker.detectKaltura = _detectKaltura;

import type { Debugger } from '../typings/debug';

type ContentType = 'application/x-www-form-urlencoded' | 'application/json';

export class TrackerClient {
  private headers = new Headers();
  private retry = 5;
  private delay = 2000;
  private credentials = false;
  private keepalive = true;
  private timeout?: NodeJS.Timeout = undefined;

  constructor(private url: string, private logger: Debugger) {}

  withContentType(value: ContentType) {
    this.headers.set('Content-type', value);
    return this;
  }

  withRetry(value: number) {
    this.retry = value;
    return this;
  }

  withDelay(value: number) {
    this.delay = value;
    return this;
  }

  withCredentials(value: boolean) {
    this.credentials = value;
    return this;
  }

  withKeepalive(value: boolean) {
    this.keepalive = value;
    return this;
  }

  async post(body: string): Promise<void> {
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        body,
        credentials: this.credentials ? 'include' : 'omit',
        headers: this.headers,
        keepalive: this.keepalive,
        mode: 'cors',
      });
      this.logger.log('post result', response);
      if (response.ok) {
        clearTimeout(this.timeout);
        return;
      }

      if (
        this.retry > 0 &&
        (response.status === 503 || response.status == 429)
      ) {
        this.retry--;
        this.timeout = setTimeout(
          async () => await this.post(body),
          this.delay
        );
        return;
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.logger.error(error);
      }
      return;
    }
  }
}

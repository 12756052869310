import { tracker } from './initoftrackerobj.js';
import { EventsSDK, EventsTracker } from './events/index.js';
import type { Events } from './typings/index.js';

const sdk = new EventsSDK(
  new EventsTracker(
    () => tracker.config,
    tracker.store,
    tracker.debugger,
    tracker.notifySubscribers,
    tracker.cookies.getCookie
  )
);

tracker.logEvent = (event: Events.GLiTREventDTO) => sdk.logEvent(event);
tracker.trackEvent = (event: Events.GLiTREvent) => sdk.trackEvent(event);
tracker.initEvent = () => {
  tracker.log('trackevent initialized');
  if (tracker.initialized) {
    tracker.initialized.trackEvent = true;
  }
};
